<template>
  <!-- begin::Users edit for TransporterAdmin role (one of his TransporterDrivers) -->
  <div>
    <div class="manager-title">
      <inline-svg
        class="manager-title-icon"
        src="/media/icons/choferes-primary.svg"
      />
      <div>
        {{ UserID > 0 ? $t("DRIVERS.TITLE_EDIT") : $t("DRIVERS.TITLE_ADD") }}
        <hr class="manager-title-sub" />
      </div>
    </div>

    <div class="white-box shadow-box">
      <div class="manager-subtitle">
        {{ $t("DRIVERS.SUBTITLE") }}
      </div>

      <v-form class="form" ref="formDriver" v-model="Valid" lazy-validation>
        <div class="row">
          <div class="form-group col-lg-4">
            <label>
              {{ $t("MY_PROFILE.NICK") }}
            </label>
            <v-text-field
              class="form-control"
              v-model="Fields.Nick"
              :placeholder="$t('MY_PROFILE.NICK')"
              required
              :rules="[rules.required]"
            />
          </div>

          <div class="form-group col-lg-4">
            <label>
              {{ $t("MY_PROFILE.FULLNAME") }}
            </label>
            <v-text-field
              class="form-control"
              v-model="Fields.Name"
              :placeholder="$t('MY_PROFILE.FULLNAME')"
              required
              :rules="[rules.required]"
            />
          </div>
        </div>

        <div class="row">
          <div class="form-group col-lg-4">
            <label>
              {{ $t("MY_PROFILE.EMAIL") }}
            </label>
            <v-text-field
              v-if="UserID > 0"
              class="form-control"
              v-model="Fields.Email"
              :placeholder="$t('MY_PROFILE.EMAIL')"
              readonly
            >
              <template v-slot:prepend-inner>
                <inline-svg src="/media/icons/contact-gray.svg" />
              </template>
            </v-text-field>
            <v-text-field
              v-else
              class="form-control"
              v-model="Fields.Email"
              :placeholder="$t('MY_PROFILE.EMAIL')"
              :rules="[rules.required, rules.email]"
              required
            >
              <template v-slot:prepend-inner>
                <inline-svg src="/media/icons/contact-gray.svg" />
              </template>
            </v-text-field>
          </div>

          <div v-if="UserID < 0" class="col-lg-8 d-flex align-items-center">
            <inline-svg src="/media/icons/help-alt-5-primary.svg" />
            <span class="pl-3">{{ $t("USERS.NEW_EMAIL_INFO") }}</span>
          </div>

          <div class="form-group col-lg-4">
            <label>
              {{ $t("MY_PROFILE.IDCARD") }}
            </label>
            <v-text-field
              class="form-control"
              v-model="Fields.IDCard"
              :placeholder="$t('MY_PROFILE.IDCARD')"
              required
              :rules="[rules.required]"
            />
          </div>

          <div class="form-group col-lg-4">
            <label>
              {{ $t("MY_PROFILE.PHONE") }}
            </label>
            <v-text-field
              class="form-control"
              v-model="Fields.Phone"
              :placeholder="$t('MY_PROFILE.PHONE')"
              :rules="[rules.required]"
              required
            >
              <template v-slot:prepend-inner>
                <inline-svg src="/media/icons/phone-gray.svg" />
              </template>
            </v-text-field>
          </div>
        </div>

        <div class="row">
          <div class="form-group col-lg-4">
            <label>
              {{ $t("DRIVERS.LINKED_TRUCK") }}
            </label>
            <select
              class="form-control select-down"
              v-model="Fields.TruckID"
              required
            >
              <option
                v-for="t in TruckOptions"
                :key="t.TruckID"
                :value="t.TruckID"
                class="text-primary"
              >
                {{ t.Nick }}
              </option>
            </select>
          </div>
        </div>

        <div class="row button-row">
          <router-link to="/manager/drivers" v-slot="{ navigate }" custom>
            <button
              class="btn btn-pill btn-white button-form button-form-primary mr-9"
              @click="navigate"
            >
              <inline-svg
                src="/media/icons/arrow-return-primary.svg"
                class="mr-3"
              />
              {{ $t("GENERIC.BACK") }}
            </button>
          </router-link>

          <button
            ref="formDriverSubmit"
            class="btn btn-pill btn-tertiary button-form"
            :disabled="!Valid"
            @click="onSubmitDriver"
          >
            {{ $t("GENERIC.SAVE") }}
          </button>
        </div>
      </v-form>
    </div>
  </div>
  <!-- end::Users edit for TransporterAdmin role-->
</template>

<script>
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from "@/core/services/store/htmlclass.module.js";

import TruckService from "@/core/services/api/v2/truck.service";
import UserService from "@/core/services/api/v2/user.service";

export default {
  name: "UsersTransporterEdit",
  data() {
    return {
      UserID: 0,
      TruckOptions: [],
      Valid: true,
      Fields: {
        Nick: "",
        Name: "",
        Email: "",
        IDCard: "",
        Phone: "",
        TruckID: -1
      },
      rules: {
        required: value => !!value || this.$i18n.t("VALIDATION.REQUIRED_FIELD"),
        email: value =>
          !!/.+@.+\..+/.test(value) || this.$i18n.t("VALIDATION.INVALID_EMAIL")
      }
    };
  },
  mounted() {
    this.loadTruckOptions();

    // Edit (a certain "id") or create new?
    if (this.$route.params.id) {
      this.UserID = parseInt(this.$route.params.id);
      this.loadDriverData();
    } else {
      this.UserID = -1;
    }
  },
  methods: {
    loadTruckOptions() {
      this.TruckOptions = [];
      this.TruckOptions.push({
        TruckID: -1,
        Nick: "[" + this.$i18n.t("DRIVERS.UNLINKED_TRUCK") + "]"
      });

      TruckService.listAll().then(response => {
        response.forEach(t => {
          this.TruckOptions.push(t);
        });
      });
    },

    loadDriverData() {
      // Show page loader
      this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");

      UserService.getDriverProfile(this.UserID).then(response => {
        if (response === 404) {
          this.$router.push("/manager/drivers");
        } else {
          for (var f1 in this.Fields) {
            this.Fields[f1] = response[f1];
          }
        }

        // Remove page loader
        this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
      });
    },

    onSubmitDriver(e) {
      e.preventDefault();

      if (this.$refs.formDriver.validate()) {
        // Disable submit button
        this.Valid = false;

        // Fix optional unassigned TruckID
        if (parseInt(this.Fields.TruckID) < 1) {
          this.Fields.TruckID = null;
        }

        // Send request:
        if (this.UserID > 0) {
          // Update Driver
          UserService.updateDriverProfile(this.UserID, this.Fields).then(
            statusCode => {
              if (statusCode >= 400) {
                let whyError = this.$i18n.t("GENERIC.TRY_LATER");
                if (statusCode === 409) {
                  whyError = this.$i18n.t("DRIVERS.ERROR_409");
                } else if (statusCode === 400) {
                  whyError = this.$i18n.t("VALIDATION.MISSING_REQUIRED_FIELDS");
                }

                this.$bvToast.toast(whyError, {
                  title: this.$i18n.t("GENERIC.SAVE_ERROR"),
                  variant: "danger",
                  solid: true
                });
              } else {
                this.$bvToast.toast(" ", {
                  title: this.$i18n.t("GENERIC.SAVE_OK"),
                  variant: "success",
                  solid: true
                });
              }

              // Reset submit button
              this.Valid = true;
            }
          );
        } else {
          // Create new Driver: we get back his ID
          UserService.createDriverProfile(this.Fields).then(response => {
            if (response.UserID) {
              this.$bvToast.toast(" ", {
                title: this.$i18n.t("GENERIC.SAVE_OK"),
                variant: "success",
                solid: true
              });

              this.UserID = response.UserID;
              setTimeout(
                () =>
                  this.$router.push("/manager/drivers/edit/" + response.UserID),
                2000
              );
            } else {
              let whyError = this.$i18n.t("GENERIC.TRY_LATER");
              if (response === 409) {
                whyError = this.$i18n.t("DRIVERS.ERROR_409");
              } else if (response === 418) {
                whyError = this.$i18n.t("DRIVERS.ERROR_418");
              } else if (response === 400) {
                whyError = this.$i18n.t("VALIDATION.MISSING_REQUIRED_FIELDS");
              }

              this.$bvToast.toast(whyError, {
                title: this.$i18n.t("GENERIC.SAVE_ERROR"),
                variant: "danger",
                solid: true
              });
            }

            // Reset submit button
            this.Valid = true;
          });
        }
      }
    }
  }
};
</script>
